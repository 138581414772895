// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { getActionsLogs } from "../../store/reducers/actionLog/actionLogActions";

// ** Custom Components
import Datatable from "../../components/Datatable/Datatable";

// ** Log Actions
import * as LOG_ACTIONS from "../../utils/enums/LogActions";

// ** Hooks
import { useDate } from "../../hooks/useDate";

const Logs = () => {
    const [action, setAction] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [email, setEmail] = useState('');
    const [filters, setFilters] = useState({});
    const [perPage, setPerPage] = useState(10);

    const { dateTimeFormat } = useDate();
    const dispatch = useDispatch();
    const intl = useIntl();
    const actionLogs = useSelector(state => state.actionLog.actionLogs);
    const locale = useSelector(state => state.locale.locale);

    useEffect(() => {
        dispatch(getActionsLogs());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: intl.formatMessage({id: "logs.action"}),
            selector: row => intl.formatMessage({id: `logs.action.${row.action}`}),
            wrap: true
        },
        {
            name: intl.formatMessage({id: "logs.description"}),
            selector: "description",
            grow: 2,
            wrap: true
        },
        {
            name: intl.formatMessage({id: "general.date"}),
            selector: row => dateTimeFormat(row.created_at, locale.name),
            wrap: true
        },
        {
            name: intl.formatMessage({id: "logs.ip"}),
            selector: "ip"
        },
        {
            name: intl.formatMessage({id: "general.email"}),
            selector: 'user_email',
            grow: 2
        },
        {
            name: intl.formatMessage({id: "general.customer"}),
            selector: 'customer_name'
        }
    ];

    const onSubmit = event => {
        event.preventDefault();

        const filters = {
            ...(action && { action }),
            ...(startDate && { start_date: startDate }),
            ...(endDate && { end_date: endDate }),
            ...(email && { email })
        };
        setFilters(filters);
        dispatch(getActionsLogs({ ...filters, page: 1, per_page: perPage }));
    }

    const onChangeRowsPerPage = perPage => {
        setPerPage(perPage);
        dispatch(getActionsLogs({ ...filters, page: 1, per_page: perPage }));
    }

    return (
        <div className="box is-desktop">
            <h4 className="title has-text-primary is-4">
                <FormattedMessage id="menu.logs"/>
            </h4>
            <section className="custom-box">
                <h4 className="has-text-primary is-5 title">
                    <FormattedMessage id="general.filter"/>
                </h4>
                <form onSubmit={onSubmit}>
                    <div className="columns is-desktop">
                        <div className="column">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="logs.action"/>
                                </label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select onChange={event => setAction(event.target.value)} value={action}>
                                            <option value=''/>
                                            {Object.values(LOG_ACTIONS).map(action => (
                                                <option key={action} value={action}>
                                                    {intl.formatMessage({id: `logs.action.${action}`})}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <label className="label">
                                <FormattedMessage id="general.date"/>
                            </label>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input is-fullwidth"
                                                onChange={event => setStartDate(event.target.value)}
                                                type="date"
                                                value={startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input is-fullwidth"
                                                onChange={event => setEndDate(event.target.value)}
                                                type="date"
                                                value={endDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="general.email"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        maxLength={255}
                                        onChange={event => setEmail(event.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="button is-info">
                                <FormattedMessage id="general.search"/>
                            </button>
                        </div>
                    </div>
                </form>
            </section>
            <div className="table-container">
                <Datatable
                    columns={columns}
                    data={actionLogs.data ?? []}
                    onChangePage={page => dispatch(getActionsLogs({ ...filters, page, per_page: perPage }))}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    paginationDefaultPage={actionLogs.current_page}
                    paginationServer
                    paginationTotalRows={actionLogs.total}
                    pointerOnHover={false}
                />
            </div>
        </div>
    );
}

export default Logs;
