import authReducer from './auth/authReducer';
import sendResetPasswordReducer from './auth/sendResetPassword/sendResetPasswordReducer';
import resetPasswordReducer from './auth/resetPassword/resetPasswordReducer';
import menuReducer from './menu/menuReducer';
import modalReducer from './modal/modalReducer';
import userReducer from './user/userReducer';
import departmentReducer from './department/departmentReducer';
import customersReducer from './customers/customersReducer';
import invoiceReducer from "./Invoice/InvoiceReducer";
import taskReducer from "./task/taskReducer";
import languageReducer from "./language/languageReducer";
import rateReducer from "./rate/rateReducer";
import { combineReducers } from 'redux';
import notificationReducer from "./notification/notificationReducer";
import guestReducer from "./guest/guestReducer";
import * as actionTypes from "../Actions";
import storage from 'redux-persist/lib/storage';
import localeReducer from "./locale/localeReducer";
import translationTypeReducer from "./translationType/translationTypeReducer";
import otherConceptReducer from "./otherConcept/otherConceptReducer";
import payTaskHistoryReducer from "./payTaskHistory/payTaskHistoryReducer";
import bonusReducer from "./bonus/bonusReducer";
import actionLogReducer from "./actionLog/actionLogReducer";
import quoteReducer from "./quote/quoteReducer";

const appReducers = combineReducers({
    auth: authReducer,
    sendresetPassword: sendResetPasswordReducer,
    resetPassword: resetPasswordReducer,
    menu: menuReducer,
    modal: modalReducer,
    notification: notificationReducer,
    user: userReducer,
    department: departmentReducer,
    customer_assing: customersReducer,
    invoice: invoiceReducer,
    task: taskReducer,
    language: languageReducer,
    rate: rateReducer,
    guest: guestReducer,
    locale: localeReducer,
    translationType: translationTypeReducer,
    otherConcept: otherConceptReducer,
    payTaskHistory: payTaskHistoryReducer,
    bonus: bonusReducer,
    actionLog: actionLogReducer,
    quote: quoteReducer,
});

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if(action.type === actionTypes.LOGOUT){
        state = undefined;
        storage.removeItem('persist:root')
    }

    return appReducers(state, action);
};

export default rootReducer;
