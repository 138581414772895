export let LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const BAD_CREDENTIALS = "BAD_CREDENTIALS";
export const RESET_BAD_CREDENTIALS = "RESET_BAD_CREDENTIALS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_AUTH_ERROR = "RESET_AUTH_ERROR";
export const CHANGE_MENU = "CHANGE_MENU";
export const GET_USERS = "GET_USERS";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const RESET_SELECTED_USER = "RESET_SELECTED_USER";
export const UPDATE_PASSWORD_STATUS = "UPDATE_PASSWORD_STATUS";
export const USER_CREATED = "USER_CREATED";
export const USER_CREATED_ERROR = "USER_CREATED_ERROR";
export const USER_DELETED = "USER_DELETED";
export const USER_UPDATED = "USER_UPDATED";
export const USER_UPDATED_ERROR = "USER_UPDATED_ERROR";
export const VALID_USERS = "VALID_USERS";
export const VALID_USERS_ERROR = "VALID_USERS_ERROR";
export const SET_USER = "SET_USER";
export const SET_USER_DEPARTMENTS = "SET_USER_DEPARTMENTS";
export const GET_PAYMENT_SCHEME = "GET_PAYMENT_SCHEME";
export const SET_PRICES = "SET_PRICES";
export const SET_USER_LOCALE = "SET_USER_LOCALE";

//Task
export const GET_TASKS = "GET_TASKS";
export const SET_PAYMENT_FORM = "SET_PAYMENT_FORM";
export const GET_TASK_ID = "GET_TASK_ID";
export const GET_SOURCE_FILES_INFORMATION = "GET_SOURCE_FILES_INFORMATION";
export const SET_REVIEW_STATE = "SET_REVIEW_STATE";
export const SET_TRANSLATE_STATE = "SET_TRANSLATE_STATE";
export const SET_APPLY_DISCOUNT_STATE = "SET_APPLY_DISCOUNT_STATE";
export const SAVE_TASK = "SAVE_TASK";
export const SET_SELECTED_TARGET_LANGUAGES = "SET_SELECTED_TARGET_LANGUAGES";
export const SET_SELECTED_SOURCE_LANGUAGE = "SET_SELECTED_SOURCE_LANGUAGE";
export const SET_TASK_NAME = "SET_TASK_NAME";
export const SET_TASK_COMMENTS = "SET_TASK_COMMENTS";
export const SET_TASK_DEPARTMENT = "SET_TASK_DEPARTMENT";
export const SET_LOADING_COUNT_STATUS = "SET_LOADING_COUNT_STATUS";
export const GET_TASK_SUCESS = "GET_TASK_SUCESS";
export const DEL_TASK_SUCESS = "DEL_TASK_SUCESS";
export const CLEAR_TASK_SUCESS = "CLEAR_TASK_SUCESS";
export const CLEAR_TASK_ERROR = "CLEAR_TASK_ERROR";
export const TASK_VERIFY_FILES = "TASK_VERIFY_FILES";
export const RESET_TASK_VERIFY_FILES = "RESET_TASK_VERIFY_FILES";
export const SAVE_TASK_AS_DRAFT = "SAVE_TASK_AS_DRAFT";
export const RESET_SAVE_TASK_AS_DRAFT = "RESET_SAVE_TASK_AS_DRAFT";
export const UPDATE_TASK_SUCESS = "UPDATE_TASK_SUCESS";
export const SET_APPROVED_TASK = "SET_APPROVED_TASK";
export const SET_PAYMENT_DONE = "SET_PAYMENT_DONE";
export const SET_BUDGET_STATUS = "SET_BUDGET_STATUS";
export const RESET_BUDGET_STATUS = "RESET_BUDGET_STATUS";
export const SET_CANCELLED_TASK = "SET_CANCELLED_TASK";
export const RESET_CANCELLED_TASK_STATUS = "RESET_CANCELLED_TASK_STATUS";
export const SET_REACTIVATED_TASK = "SET_REACTIVATED_TASK";
export const RESET_REACTIVATED_TASK_STATUS = "RESET_REACTIVATED_TASK_STATUS";
export const SET_DELETED_TASK = "SET_DELETED_TASK";
export const RESET_DELETED_TASK_STATUS = "RESET_DELETED_TASK_STATUS";
export const SET_DOWNLOADED_BUDGET = "SET_DOWNLOADED_BUDGET";
export const RESET_DOWNLOADED_BUDGET = "RESET_DOWNLOADED_BUDGET";

export const GET_RATES = "GET_RATES";
export const CAN_USER_REVIEW_ORIGINAL = "CAN_USER_REVIEW_ORIGINAL";
export const RESET_CAN_USER_REVIEW_ORIGINAL = "RESET_CAN_USER_REVIEW_ORIGINAL";
export const GET_RATES_SOURCE_LANGUAGES = "GET_RATES_SOURCE_LANGUAGES";
export const GET_RATES_TARGET_LANGUAGES = "GET_RATES_TARGET_LANGUAGES";

export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";

export const UPLOAD_TRANSLATED_FILE_SUCCESS = "UPLOAD_TRANSLATED_FILE_SUCCESS";
export const UPLOAD_TRANSLATED_FILE_ERROR = "UPLOAD_TRANSLATED_FILE_ERROR";
export const RESET_UPLOAD_TRANSLATED_FILE_SUCCESS = "RESET_UPLOAD_TRANSLATED_FILE_SUCCESS";
export const REMOVE_TRANSLATED_FILE_SUCCESS = "REMOVE_TRANSLATED_FILE_SUCCESS";
export const RESET_REMOVE_TRANSLATED_FILE_SUCCESS = "RESET_REMOVE_TRANSLATED_FILE_SUCCESS";
export const REMOVE_TRANSLATED_FILE_ERROR = "REMOVE_TRANSLATED_FILE_ERROR";

//Department
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const RESET_CREATED_DEPARTMENT = "RESET_CREATED_DEPARTMENT";
export const RESET_DELETED_DEPARTMENT = "RESET_DELETED_DEPARTMENT";
export const RESET_UPDATED_DEPARTMENT = "RESET_UPDATED_DEPARTMENT";
export const SET_CURRENT_USER_DEPARTMENTS = "SET_CURRENT_USER_DEPARTMENTS";

//invoices
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICE = "GET_INVOICE";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const RESET_CREATED_INVOICE = "RESET_CREATED_INVOICE";
export const RESET_DELETED_INVOICE = "RESET_DELETED_INVOICE";
export const RESET_UPDATED_INVOICE = "RESET_UPDATED_INVOICE";
export const DOWNLOADED_INVOICE = "DOWNLOADED_INVOICE";
export const RESET_DOWNLOADED_INVOICE = "RESET_DOWNLOADED_INVOICE";

//languages
export const GET_SOURCE_LANGUAGES = "GET_SOURCE_LANGUAGES";
export const GET_TARGET_LANGUAGES = "GET_TARGET_LANGUAGES";
export const GET_AVAILABLE_LANGUAGES = "GET_AVAILABLE_LANGUAGES";
export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES";
export const SET_LOCALE = "SET_LOCALE";

export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

/**
 * To reset password
 */
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const RESET_SENDLINK = "RESET_SENDLINK";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const VALID_TOKEN = "VALID_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const CHANGE_NEW_PASSWORD = "CHANGE_NEW_PASSWORD";
export const CHANGE_CONFIRM_NEW_PASSWORD = "CHANGE_CONFIRM_NEW_PASSWORD";
export const RESET_TOKEN_VALID = "RESET_TOKEN_VALID";
export const RESET_ERRORS = "RESET_ERRORS";

/**
 * Invoice Data
 */

export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
export const RESET_UPDATE_INVOICE_DATA = "RESET_UPDATE_INVOICE_DATA";

/*
* Customersº
* */
export const SET_SIMPLE_CUSTOMERS = "SET_SIMPLE_CUSTOMERS";
export const SET_CUSTOMER_DISCOUNT = "SET_CUSTOMER_DISCOUNT";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_TEMPORAL_TASK = "GET_TEMPORAL_TASK";
export let PAY_TEMPORAL_TASK = "PAY_TEMPORAL_TASK";
export let SEND_TASK_TO_PRODUCTION = "SEND_TASK_TO_PRODUCTION";
export let RESET_SEND_TASK_TO_PRODUCTION = "RESET_SEND_TASK_TO_PRODUCTION";

export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS";
export const DELETE_ENABLE_RATE_SUCCESS = "DELETE_ENABLE_RATE_SUCCESS";

// Translation types
export const GET_TRANSLATION_TYPES = "GET_TRANSLATION_TYPES";

// Register
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

// Other concepts
export const CREATE_OTHER_CONCEPT = "CREATE_OTHER_CONCEPT";
export const UPDATE_OTHER_CONCEPT = "UPDATE_OTHER_CONCEPT";
export const DELETE_OTHER_CONCEPT = "DELETE_OTHER_CONCEPT";

// Pay task history
export const CREATE_PAY_TASK_HISTORY = "CREATE_PAY_TASK_HISTORY";

// Bonuses
export const GET_BONUSES = "GET_BONUSES";

// Action logs
export const GET_ACTION_LOGS = "GET_ACTION_LOGS";
